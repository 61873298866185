import React from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { configJSON } from "../../DashboardController.web";
import { CustomTableHeader } from "../CustomTableHeader";
import ImageWithName from "../ImageWithName";
import { PaginationStyled } from "../Table/tableElements";
import { IRecommendedConsultant } from "../../interfaces";
import {
  ScheduleAppointmentWrapper,
  StyledExperties,
  StyledTableCellContainerDiv,
  StyledTableRow,
  TableContainer,
  StyledPaginationContainer,
  TableContainerBox
} from "../../../../forgot-password/src/Components/CommonStyledComponent";

interface Props {
  consultants: IRecommendedConsultant[];
  currentPage: number;
  totalPage: number;
  handleChange: (event: React.ChangeEvent<unknown>, newPage: number) => void;
}

export const MyConsultant = (props: Props) => {
  return (
    <Box>
      <TableContainer elevation={3}>
        <TableContainerBox>
          <Table>
            <CustomTableHeader
              headers={configJSON.employeeConsultantTableHeaderArr}
            />
            <TableBody>
              {props.consultants.map((consultant: IRecommendedConsultant) => (
                <MyConsultantTableBody
                  consultant={consultant}
                  key={consultant.id}
                />
              ))}
            </TableBody>
          </Table>
          <StyledPaginationContainer>
            <PaginationStyled
              count={props.totalPage}
              variant="outlined"
              shape="rounded"
              page={props.currentPage}
              onChange={props.handleChange}
            />
          </StyledPaginationContainer>
        </TableContainerBox>
      </TableContainer>
    </Box>
  );
};

const MyConsultantTableBody = (props: {
  consultant: IRecommendedConsultant;
}) => (
  <StyledTableRow>
    <TableCell>
      <StyledTableCellContainerDiv>
        <ImageWithName
          name={`${props.consultant.attributes.full_name}`}
          imgUrl={props.consultant.attributes.profile.data.attributes.photo}
        />
      </StyledTableCellContainerDiv>
    </TableCell>
    <TableCell>
      <StyledTableCellContainerDiv>
        {props.consultant.attributes.profile.data.attributes
          .area_of_expertise &&
          props.consultant.attributes.profile.data.attributes.area_of_expertise.map(
            (experties: string) => (
              <StyledExperties>{experties}</StyledExperties>
            )
          )}
      </StyledTableCellContainerDiv>
    </TableCell>
    <TableCell>
      <StyledTableCellContainerDiv>
        {props.consultant.attributes.profile.data &&
          props.consultant.attributes.profile.data.attributes.company}
      </StyledTableCellContainerDiv>
    </TableCell>
    <TableCell>
      <StyledTableCellContainerDiv>
        {props.consultant.attributes.no_of_employees}
      </StyledTableCellContainerDiv>
    </TableCell>
    <TableCell>
      <StyledTableCellContainerDiv>
        {props.consultant.attributes.no_of_employees}
      </StyledTableCellContainerDiv>
    </TableCell>
    <TableCell>
      <StyledTableCellContainerDiv>
        <ScheduleAppointmentWrapper>
          Reschedule Appointment
        </ScheduleAppointmentWrapper>
      </StyledTableCellContainerDiv>
    </TableCell>
  </StyledTableRow>
);
