import React from "react";

const PasswordValidations = () => (
  <>
    <div>* Password should be 8-15 character in length.</div>
    <div>* Password should be contain atleast 1 uppercase letter.</div>
    <div>* Password should be contain atleast 1 lowercase letter.</div>
    <div>* Password should be contain atleast 1 digit.</div>
    <div>* Password should be contain atleast 1 special character.</div>
  </>
);

export default PasswordValidations;
