import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import { AdminPage } from './types/adminPage';
// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedPage: AdminPage;
  openAddNewUser: boolean;
  openRemoveAccess: boolean;
  employeeList: any[];
  consultantList: any[];
  logoutData: any;
  searchList: any[];
  inviteList: any;
  removeList: any;
  pendingApprovalList: any[]
  employeeListSuccessful: boolean;
  consultantListSuccessful: boolean;
  logoutSuccesful: boolean;
  searchListSuccessful: boolean;
  inviteListSuccessful: boolean;
  removeSuccessful: boolean;
  pendingApprovalSuccessful: boolean,
  isEmployeeListError: boolean;
  isConsultantListError: boolean;
  isLogoutError: boolean;
  isSearchListError: boolean;
  isInviteListError: boolean;
  isRemoveError: boolean;
  isPendingApprovalError: boolean;
  employeeListErrorMessage: string;
  consultantListErrorMessage: string;
  logoutErrorMessage: string;
  searchListErrorMessage: string;
  inviteListErrorMessage: string;
  removeErrorMessage: string;
  pendingApprovalErrorMessage: string;
  search: any;
  sendSearch: boolean;
  inviteEmail: string;
  isInviteEmailValid: boolean | null;
  searchType: string;
  removeID: any;
  page: any;
  approveOrDecline: any;
  approveorDeclineId: any;
  logoutForm: boolean;
  approveForm: boolean;
  declineForm: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class AdminConsoleController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  employeesListRequestId: string = "";
  consultantsListRequestId: string = "";
  logoutRequestId: string = "";
  searchRequestId: string = "";
  inviteRequestId: string = "";
  removeRequestId: string = "";
  pendingApprovalRequestId: string = "";
  approveDeclineRequestId: string = "";  
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.AuthTokenDataMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: '',
      txtSavedValue: 'A',
      enableField: false,
      // Customizable Area Start
      selectedPage: 'dashboard',
      openAddNewUser: false,
      openRemoveAccess: false,
      employeeList: [],
      consultantList: [],
      logoutData: {},
      searchList: [],
      inviteList: {},
      removeList: {},
      pendingApprovalList: [],
      employeeListSuccessful: true,
      consultantListSuccessful: true,
      logoutSuccesful: true,
      searchListSuccessful: true,
      inviteListSuccessful: true,
      removeSuccessful: true,
      pendingApprovalSuccessful: true,
      isEmployeeListError: false,
      isConsultantListError: false,
      isLogoutError: false,
      isSearchListError: false,
      isInviteListError: false,
      isRemoveError: false,
      isPendingApprovalError: false,
      employeeListErrorMessage: "",
      consultantListErrorMessage: "",
      logoutErrorMessage: "",
      searchListErrorMessage: "",
      inviteListErrorMessage: "",
      removeErrorMessage: "",
      pendingApprovalErrorMessage: "",
      search: "",
      sendSearch: false,
      inviteEmail: "",
      isInviteEmailValid: null,
      searchType: "",
      removeID: "",
      page: 1,
      approveOrDecline: "",
      approveorDeclineId: "",
      logoutForm: false,
      approveForm: false,
      declineForm: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }



  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {

      // Customizable Area Start

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.employeesListRequestId:
          this.handleEmployeesListResponse(responseJson);
          break;
    
        case this.consultantsListRequestId:
          this.handleConsultantsListResponse(responseJson);
          break;
    
        case this.logoutRequestId:
          this.handleLogoutResponse(responseJson);
          break;
    
        case this.searchRequestId:
          this.handleSearchResponse(responseJson);
          break;
    
        case this.inviteRequestId:
          this.handleInviteResponse(responseJson);
          break;
    
        case this.removeRequestId:
          this.handleRemoveResponse(responseJson);
          break;
    
        case this.pendingApprovalRequestId:
          this.handlePendingApprovalResponse(responseJson);
          break;
    
        case this.approveDeclineRequestId:
          this.getPendingRequest();
          break;
    
        default:
          break;
      }
     
    }
    // Customizable Area End
  }

  // Customizable Area Start   

  handleEmployeesListResponse(responseJson:any) {
    if (responseJson.errors) {
      this.setState({
        employeeListSuccessful: false,
        isEmployeeListError: true,
        employeeListErrorMessage: responseJson.errors[0].token,
      });
    }
  
    else if (responseJson.data) {
      this.setState({
        employeeListSuccessful: true,
        isEmployeeListError: false,
        employeeListErrorMessage: "",
        employeeList: responseJson.data,
      });
    }
  }
  
  handleConsultantsListResponse(responseJson:any) {
    if (responseJson.errors) {
      this.setState({
        consultantListSuccessful: false,
        isConsultantListError: true,
        consultantListErrorMessage: responseJson.errors[0].token,
      });
    }
  
    else if (responseJson.data) {
      this.setState({
        consultantListSuccessful: true,
        isConsultantListError: false,
        consultantListErrorMessage: "",
        consultantList: responseJson.data,
      });
    }
  }
  
  handleLogoutResponse(responseJson:any) {
    if (responseJson.errors) {
      this.setState({
        logoutSuccesful: false,
        isLogoutError: true,
        logoutErrorMessage: responseJson.errors[0].token,
      });
    }
  
    else if (responseJson.message) {
      this.setState({
        logoutSuccesful: true,
        isLogoutError: false,
        logoutErrorMessage: "",
        logoutData: responseJson,
      });
      window.localStorage.clear();
      window.location.replace(configJSON.loginPath);
    }
  }
  
  handleSearchResponse(responseJson:any) {
    if (responseJson.errors) {
      this.setState({ searchListSuccessful: false })
      this.setState({ isSearchListError: true, searchListErrorMessage: responseJson.errors[0].message })
    }
    else if (responseJson.data) {
      this.setState({ searchListSuccessful: true })
      this.setState({ isSearchListError: false, searchListErrorMessage: "", })
      this.setState({ searchList: responseJson.data })
    }
    else{
      this.setState({ searchList: [] })
    }
  }

  handleInviteResponse(responseJson:any) {
    if (responseJson.errors) {
      this.setState({
        inviteListSuccessful: false,
        isInviteListError: true,
        inviteListErrorMessage: responseJson.errors[0].token,
      });
    }
  
    else if (responseJson.message) {
      this.setState({
        inviteListSuccessful: true,
        isInviteListError: false,
        inviteListErrorMessage: "",
        inviteList: responseJson.message,
      });
      this.setState({
        inviteEmail: "",
        openAddNewUser: false,
      });
    }
  }
  
  handleRemoveResponse(responseJson:any) {
    if (responseJson.errors) {
      this.setState({
        removeSuccessful: false,
        isRemoveError: true,
        removeErrorMessage: responseJson.errors[0],
      });
    }
  
    else if (responseJson.data) {
      this.setState({
        removeSuccessful: true,
        isRemoveError: false,
        removeErrorMessage: "",
        removeList: responseJson.data,
        openRemoveAccess: false,
        removeID: "",
      });
  
      if (this.state.searchType === "employee") {
        this.getEmployeesList();
      } else {
        this.getConsultantList();
      }
  
      if (this.state.sendSearch === true) {
        this.getSearch();
      }
    }
  }
  
  handlePendingApprovalResponse(responseJson:any) {
    if (responseJson.errors) {
      this.setState({
        pendingApprovalSuccessful: false,
        isPendingApprovalError: true,
        pendingApprovalErrorMessage: responseJson.errors[0].token,
      });
    }
  
    else if (responseJson.data) {
      this.setState({
        pendingApprovalSuccessful: true,
        isPendingApprovalError: false,
        pendingApprovalErrorMessage: "",
        pendingApprovalList: responseJson.data,
      });
    }
  }

  // Customizable Area End


  // Customizable Area Start
  setSelectedPage = (page: AdminPage) => {
    this.setState({ selectedPage: page, page: 1 });
    if (page === 'employees') {
      this.setState({ searchType: "employee", search: "", sendSearch: false })
      this.getEmployeesList()
    } else if (page === "consultants") {
      this.setState({ searchType: "consultant", search: "", sendSearch: false })
      this.getConsultantList()
    } else {
      this.setState({ searchType: "" })
    }
  };

  handleChangePage = (newPage: number) => {
    this.setState({ page: newPage })
  }

  handleOpenAddNewUser = () => {
    this.setState({ openAddNewUser: true });
  };


  handleCloseAddNewUser = () => {
    this.setState({ openAddNewUser: false });
  };

  handleOpenLogoutForm = () => {
    this.setState({ logoutForm: true })
  }

  handleCloseLogoutForm = () => {
    this.setState({ logoutForm: false })
  }

  handleOpenApproveForm = (status: any, id: any) => {
    this.setState({ approveForm: true })
    this.setState({ approveOrDecline: status, approveorDeclineId: id })
  }

  handleOpenDeclineForm = (status: any, id: any) => {
    this.setState({ declineForm: true })
    this.setState({ approveOrDecline: status, approveorDeclineId: id })
  }

  handleCloseApproveForm = () => {
    this.setState({ approveForm: false })
  }

  handleCloseDeclineForm = () => {
    this.setState({ declineForm: false })
  }


  handleOpenRemoveAccess = (props: any) => {
    this.setState({ openRemoveAccess: true });
    this.setState({ removeID: props })
  };

  handleCloseRemoveAccess = () => {
    this.setState({ openRemoveAccess: false });
    this.setState({ removeID: "" })
  };

  handleRemove = () => {
    this.getRemoveAccess()
  }

  handleApproveDecline = () => {
    this.getActionOnPendingRequest()
    this.setState({ approveForm: false, declineForm: false })
  }

  handleChangeInvite = (event: any) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({ ...this.state, [name]: value })
  }


  sendInvite = () => {
    this.validateInviteEmail(this.getInvite)
  }

  validateInviteEmail = (callBackFunc?: () => void) => {
    if (/\w+/.test(this.state.inviteEmail)) {
      const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/i;
      if (emailRegex.test(this.state.inviteEmail)) {
        this.setState({ isInviteEmailValid: true }, () => {
          if (typeof callBackFunc === "function") {
            callBackFunc();
          }
        });
      } else {
        this.setState({ isInviteEmailValid: false });
      }
    } else {
      this.setState({ isInviteEmailValid: false });
    }
  };

  handleLogout = () => {
    this.getLogout()
  }

  handleChangeSearch = (event: any) => {
    const name: any = event.target.name;
    const value: any = event.target.value;
  
    this.setState({ ...this.state.search, [name]: value }, () => {
      if (this.state.search !== "" && this.state.searchType !== "") {
        this.setState({ sendSearch: true }, () => {
          this.getSearch();
        });
      } else {
        this.setState({ sendSearch: false });
      }
    });
  }

  componentDidMount(): any {
    this.getPendingRequest()
  }





  getEmployeesList = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": window.localStorage.getItem("web_token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.employeesListRequestId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts?account_type=employee`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getConsultantList = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": window.localStorage.getItem("web_token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.consultantsListRequestId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts?account_type=consultant`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getLogout = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": window.localStorage.getItem("web_token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.logoutRequestId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_login/logouts/1`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE",
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getSearch = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": window.localStorage.getItem("web_token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.searchRequestId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/search_paginated?query=${this.state.search}&type=${this.state.searchType}&per_page=10&page=${this.state.page}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getInvite = async () => {

    if (this.state.isInviteEmailValid) {

      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": window.localStorage.getItem("web_token")
      };

      let body = {
        "data": {
          "attributes": {
            "email": this.state.inviteEmail,
          },
          "type": "email_account"
        }
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );
      this.inviteRequestId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/accounts/add_consultant`,
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header),
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body),
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST",
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  getRemoveAccess = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": window.localStorage.getItem("web_token")
    };

    let body = {
      "data": {
        "attributes": {
          "status": "suspended",
        },
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.removeRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.removeID}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT",
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getPendingRequest = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": window.localStorage.getItem("web_token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.pendingApprovalRequestId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/pending_user_requests`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getActionOnPendingRequest = async () => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": window.localStorage.getItem("web_token")
    };

    let body = {
      "data": {
        "attributes": {
          "status": this.state.approveOrDecline,
        },
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.approveDeclineRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.approveorDeclineId}/update_account_request`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH",
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // Customizable Area End
}
