import { styled } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import MuiTableContainer from "@material-ui/core/TableContainer";

export const StyledInputContainer = styled("div")({
  fontFamily: "Source Sans Pro",
  cursor: "default",
  marginBottom: "1rem",
  "& h6": {
    marginBottom: "5px"
  }
});

export const StyledErrorLabel = styled(Typography)(
  (theme: { className: string }) => ({
    visibility: theme.className === "visible" ? "visible" : "hidden",
    color: "#FF1D1D"
  })
);

export const StyledIconButton = styled(IconButton)(
  (theme: { className: string }) => ({
    visibility: theme.className === "hidden" ? "hidden" : "visible"
  })
);

export const StyledButton = styled(Button)({
  backgroundColor: "#2596BE",
  color: "#ffff",
  paddingLeft: "3.5rem",
  paddingRight: "3.5rem",
  marginTop: ".2rem",
  marginBottom: ".5rem",
  "&:hover": {
    backgroundColor: "rgba(37, 150, 190, .8)"
  }
});

export const StyledErrorOnLimit = styled("div")(
  (theme: { className: string }) => ({
    visibility: theme.className === "visible" ? "visible" : "hidden",
    color: "#FF0000",
    display: "flex",
    marginTop: "2rem",
    "& div": {
      lineHeight: 3
    }
  })
);

export const StyledTableCellContainerDiv = styled("div")({
  display: "flex",
  fontSize: "1rem",
  fontFamily: "Source Sans Pro"
});

export const StyledExperties = styled("div")({
  fontWeight: 400,
  backgroundColor: "#2596BE1A",
  color: "#2596BE",
  fontSize: "1rem",
  margin: "0 5px",
  padding: "5px 5px",
  fontFamily: "Source Sans Pro",
  borderRadius: "5px"
});

export const StyledTableRow = styled(TableRow)({
  fontFamily: "Source Sans Pro"
});

export const TableContainer = withStyles({
  root: {
    padding: "2em 1.5rem",
    marginBottom: "2rem"
  }
})(Paper);

export const StyledPaginationContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "2rem"
});

export const TableContainerBox = withStyles({
  root: {
    marginTop: "1rem",
    fontFamily: "Source Sans Pro"
  }
})(Box);

export const ActionWapper = withStyles({
  root: {
    color: "#BE4D25",
    cursor: "pointer",
    fontFamily: "Source Sans Pro"
  }
})(Box);

export const ScheduleAppointmentWrapper = withStyles({
  root: {
    color: "#2596BE",
    cursor: "pointer",
    fontFamily: "Source Sans Pro"
  }
})(Box);

export const CustomTableCell = withStyles({
  root: {
    border: "none",
    fontWeight: 600,
    fontSize: "1rem",
    fontFamily: "Source Sans Pro"
  }
})(TableCell);

export const CunstomTableHead = withStyles({
  root: {
    zIndex: 3,
    position: "sticky",
    top: "0px"
  }
})(TableHead);

export const CustomTableRow = withStyles({
  root: {
    background: "rgba(241, 242, 245, 0.6)"
  }
})(TableRow);

export const TableContainerLabel = withStyles({
  root: {
    fontWeight: 600,
    fontSize: "1.125rem",
    fontFamily: "Source Sans Pro"
  }
})(Typography);

export const RecommendedTableContainerBox = withStyles({
  root: {
    marginTop: "1rem",
    fontFamily: "Source Sans Pro",
    maxHeight: "400px",
    position: "relative"
  }
})(MuiTableContainer);
