export const webStyle = {
  container: {
    backgroundColor: "#EEF0F4",
    minHeight: "100vh"
  },
  containerWrapper: {
    position: "inherit",
    zIndex: 1,
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2749) 47.95%, rgba(0, 0, 0, 0) 100%)`,
    padding: "6rem 4rem"
  },
  containerBox: {
    margin: "3rem 0",
    padding: "1rem 0"
  },
  checkCircleIcon: {
    color: "white",
    fontSize: "1rem",
    marginTop: "4px"
  },
  sectionPoint: {
    color: "white",
    fontSize: "20px",
    fontFamily: "Source Sans Pro",
    cursor: "default"
  },
  positionRelative: {
    position: "relative"
  },
  containerBoxSection2: {
    marginTop: "6.5rem ",
    marginRight: "3.5rem",
    marginLeft: "7rem",
    padding: "5rem 0"
  },
  bgImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0
  },
  formSection: {
    marginTop: "3rem"
  },
  errorIcon: {
    color: "#FF1D1D"
  },
  successIcon: {
    color: "#08B71A"
  },
  imageBottom: {
    position: "absolute",
    bottom: "1rem",
    right: 0
  },
  subTitle: {
    color: "#5E5E5E",
    width: "450px"
  },
  error: {
    color: "#FF1D1D",
    marginTop: "5px",
    marginLeft: "2px"
  },
  info: {
    padding: 0,
    marginLeft: "2px",
    top: "-1"
  },
  infoIcon: {
    fontSize: "1.1rem"
  },
  buttonContainer: {
    marginTop: "1rem"
  },
  link: {
    textDecoration: "none",
    color: "#ffff"
  },
  marginBottom: {
    marginBottom: ".5rem"
  },
  success: {
    color: "#08B71A",
    marginTop: "5px",
    marginLeft: "2px"
  },
  successResetPassword: {
    marginTop: "10px"
  },
  mainWrapper: {
    display: "flex",
    minHeight: "96.6vh"
  },
  appBarContainer: {
    padding: "0 40px",
    display: "flex",
    alignItems: "center"
  },
  searchContainer: {
    color: "#000000",
    display: "flex",
    alignItems: "center",
    marginLeft: "176px",
    gridGap: "22px"
  },
  searchIcon: {
    color: "#2596BE"
  },
  avatarSectionContainer: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    gridGap: "30px"
  },
  avatarWithActionContainer: {
    display: "flex",
    alignItems: "center",
    gridGap: "10px"
  },
  leftMenu: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "calc(100% - 147px)",
    maxWidth: "270px",
    bgcolor: "#FFFFFF",
    paddingTop: "147px",
    display: "flex",
    flexFlow: "column",
    gap: "20px"
  },
  menuWrapper: {
    height: "100%",
    padding: "0 40px",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    gap: "20px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  upperMenuWrapper: {
    display: "flex",
    flexFlow: "column",
    gap: "20px"
  },
  footMenuWrapper: {
    display: "flex",
    flexFlow: "column",
    gap: "20px"
  },
  termsAndConditionsWrapper: {
    padding: "0 40px",
    borderTop: "1px solid #0000001A"
  },
  mainSection: {
    padding: "110px 40px 40px 310px",
    width: "100%",
    bgcolor: "#F1F2F5"
  },
  dashboardButtonText: {
    content: "attr(data-text)"
  },
  activeButton: {
    fontWeight: 700,
    backgroundColor: "#2596BE1A",
    color: "#2596BE"
  },
  noActiveButton: {
    color: "#1A1A1A"
  },
  avatar: { width: "50px", height: "50px" },
  displayNone: {
    display: "none"
  },
  gridContainer: {
    backgroundColor: "#EEF0F4",
    minHeight: "100vh"
  },
  signUpContainerWrapper: {
    position: "inherit",
    zIndex: 1,
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2749) 47.95%, rgba(0, 0, 0, 0) 100%)`,
    padding: "6rem 4rem"
  },
  signUpContainerBox: {
    margin: "3rem 0",
    padding: "1rem 0"
  },

  signUpCheckCircleIcon: {
    color: "white",
    fontSize: "1rem",
    marginTop: "4px"
  },
  signUpSectionPoint: {
    color: "white",
    fontSize: "20px",
    fontFamily: "Source Sans Pro",
    cursor: "default"
  },

  signUpPositionRelative: {
    position: "relative"
  },

  signUpContainerBoxSection2: {
    marginTop: "6.5rem ",
    marginRight: "3.5rem",
    marginLeft: "7rem",
    padding: "5rem 0"
  },

  signUpBgImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0
  },
  signUpFormSection: {
    marginTop: ".5rem"
  },

  signUpErrorIcon: {
    color: "#FF1D1D"
  },
  signUpSuccessIcon: {
    color: "#08B71A"
  },
  signUpImageBottom: {
    position: "absolute",
    bottom: "1rem",
    right: 0
  },
  signUpSubTitle: {
    color: "#5E5E5E",
    width: "450px"
  },

  signUpError: {
    color: "#FF1D1D",
    marginTop: "5px",
    marginLeft: "2px"
  },

  signUpInfo: {
    padding: 0,
    marginLeft: "5px"
  },
  signUpButtonContainer: {
    display: "flex",
    gap: "10px"
  }
} as Record<
  string,
  Record<string, string | number | Record<string, string | number>>
>;
