import React from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { configJSON } from "../../DashboardController.web";
import { CustomTableHeader } from "../CustomTableHeader";
import ImageWithName from "../ImageWithName";
import { PaginationStyled } from "../Table/tableElements";
import { IAppointment } from "../../interfaces";
import { CancelAppointment } from "./CancelAppointmentPopUp";
import {
  ActionWapper,
  TableContainerBox,
  StyledExperties,
  StyledTableCellContainerDiv,
  StyledTableRow,
  TableContainer,
  StyledPaginationContainer
} from "../../../../forgot-password/src/Components/CommonStyledComponent";

interface Props {
  appointments: IAppointment[];
  currentPage: number;
  totalPage: number;
  handleChange: (event: React.ChangeEvent<unknown>, newPage: number) => void;
  openForm: boolean;
  handleFormClose: () => void;
  handleOpenForm: (id: string) => void;
  cancelAppointment: () => void;
}

export const Appointment = (props: Props) => {
  return (
    <Box>
      <TableContainer elevation={3}>
        <TableContainerBox>
          <Table>
            <CustomTableHeader
              headers={configJSON.employeeAppointmentTableHeaderArr}
            />
            <TableBody>
              {props.appointments.map((appointment: IAppointment) => (
                <StyledTableRow key={appointment.id}>
                  <TableCell>
                    <StyledTableCellContainerDiv>
                      <ImageWithName
                        name={`${appointment.attributes.consultant.full_name}`}
                        imgUrl={appointment.attributes.photo}
                      />
                    </StyledTableCellContainerDiv>
                  </TableCell>
                  <TableCell>
                    <StyledTableCellContainerDiv>
                      {appointment.attributes.area_of_expertise &&
                        appointment.attributes.area_of_expertise.map(
                          (experties: string) => (
                            <StyledExperties key={experties}>
                              {experties}
                            </StyledExperties>
                          )
                        )}
                    </StyledTableCellContainerDiv>
                  </TableCell>
                  <TableCell>
                    <StyledTableCellContainerDiv>
                      {appointment.attributes.company}
                    </StyledTableCellContainerDiv>
                  </TableCell>
                  <TableCell>
                    <StyledTableCellContainerDiv>
                      {appointment.attributes.appointment_datetime}
                    </StyledTableCellContainerDiv>
                  </TableCell>
                  <TableCell>
                    <StyledTableCellContainerDiv>
                      {appointment.attributes.viewable_slot.duration}
                    </StyledTableCellContainerDiv>
                  </TableCell>
                  <TableCell>
                    <StyledTableCellContainerDiv
                      onClick={() => props.handleOpenForm(appointment.id)}
                    >
                      <ActionWapper>Cancel</ActionWapper>
                    </StyledTableCellContainerDiv>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <StyledPaginationContainer>
            <PaginationStyled
              count={props.totalPage}
              variant="outlined"
              shape="rounded"
              page={props.currentPage}
              onChange={props.handleChange}
            />
          </StyledPaginationContainer>
        </TableContainerBox>
      </TableContainer>
      <CancelAppointment
        title={configJSON.cancelAppointmentFormTitle}
        subtitle={configJSON.cancelAppointmentFormSubTitle}
        open={props.openForm}
        handleClose={props.handleFormClose}
        cancelAppointment={props.cancelAppointment}
      />
    </Box>
  );
};
