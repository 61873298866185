import React from 'react';
import { Box, Grid } from '@material-ui/core';
import {
  imgActiveEmployeesMock,
  imgNotificationsMock,
  imgActiveConsultantsMock,
} from './mocks/mocks';
import { withStyles } from '@material-ui/core/styles';
import { AcceptButton, DeclineButton, DividerList, MailLink, PendingApprovalsBox, PendingApprovalsButtonBox, PendingApprovalsHeading, PendingApprovalsText, PendingApprovalsWrapper } from '../../styledElements';

export const DashboardSection = ({pendingList,click,clicks}:any) => {

  return (
    <DashboardWrapper>
      <MainSectionWrapper>
        <ActiveEmployeesImgWrapper>
          <img src={imgActiveEmployeesMock} alt='Active Employees' style={{ width: '100%' }} />
        </ActiveEmployeesImgWrapper>

        <Box >
          <Grid container spacing={2}>
            <Grid item xs={6}>
            <Box>
            <img src={imgActiveConsultantsMock} alt='Active Employees' style={{ width: '100%' }} />
          </Box>
            </Grid>
            <Grid item xs={6}>
            <ApprovalRequestImgWrapper >
            <PendingApprovalsWrapper> 
              <PendingApprovalsHeading>Approval requests</PendingApprovalsHeading>
              {pendingList.map(({id,attributes:{first_name,last_name,created_at,email}}:any)=>{
                   const date = new Date(created_at);
                   const day = date.getDate();
                   const month = date.toLocaleString('default', { month: 'short' });
                   const year = date.getFullYear();
                   const hours = date.getHours();
                   const minutes = date.getMinutes();
                   const amOrPm = hours >= 12 ? 'pm' : 'am';
                   const formattedTime = `${hours}:${minutes} ${amOrPm}`
                   const formattedDate = `${day} ${month} ${year}`;
              return(
                <PendingApprovalsBox key={id} >
                  <PendingApprovalsText><strong style={strong}>{first_name} {last_name}</strong> create a new account for <MailLink href='mailto:${email}'>{email}</MailLink> at {formattedTime} {formattedDate} .</PendingApprovalsText>
                  <PendingApprovalsButtonBox>
                  <AcceptButton onClick={()=>{click("regular", id)}} >Approve</AcceptButton>
                  <DeclineButton onClick={()=>{clicks("rejected",id)}} >Decline</DeclineButton>   
                  </PendingApprovalsButtonBox>
                  <DividerList />
                </PendingApprovalsBox>
              )
              })}
             </PendingApprovalsWrapper>   
          </ApprovalRequestImgWrapper>
            </Grid>
          </Grid>
                    
        </Box>
      </MainSectionWrapper>
      
      <NotificationImgWrapper>
        <img src={imgNotificationsMock} alt='Active Employees' style={{ width: '100%' }} />
      </NotificationImgWrapper>
    </DashboardWrapper>
  );
};

const DashboardWrapper = withStyles({
  root: {
    display: 'flex',
    gap: '20px',
  },
})(Box);

const MainSectionWrapper = withStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    gap: '30px',
  },
})(Box);

const ActiveEmployeesImgWrapper = withStyles({})(Box);

const ApprovalRequestImgWrapper = withStyles({
  root:{
    boxSizing: "border-box",
  }
})(Box);

const NotificationImgWrapper = withStyles({
  root: {
    width: '300px',
  },
})(Box);

const strong = {
  color:"#000000"
}