import TableHead from "@material-ui/core/TableHead";
import React from "react";
import {
  CustomTableCell,
  CustomTableRow
} from "../../../forgot-password/src/Components/CommonStyledComponent";

export const CustomTableHeader = ({ headers }: { headers: string[] }) => {
  return (
    <TableHead>
      <CustomTableRow>
        {headers.map((label: string) => (
          <CustomTableCell key={label}>{label}</CustomTableCell>
        ))}
      </CustomTableRow>
    </TableHead>
  );
};
