import React from "react";

import {
  Box,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  FormControl,
  OutlinedInput,
  styled,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ThemeProvider } from "@material-ui/core/styles";
import CheckCircleOutlineOutlined from "@material-ui/icons/CheckCircleOutlineOutlined";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { bg_image, logo_white } from "./assets";
import {Link} from "react-router-dom";
import BasicLayout from "../../forgot-password/src/Components/BasicLayout"
import { StyledButton,StyledErrorLabel,StyledIconButton,StyledInputContainer,StyledErrorOnLimit} from "../../forgot-password/src/Components/CommonStyledComponent"
import {theme} from "../../forgot-password/src/Components/theme"
import {webStyle} from "../../forgot-password/src/Components/webStyles"

// Customizable Area End

import EmailAccountLoginController, {
  Props,
  configJSON
} from "./EmailAccountLoginController.web";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  StyledOutlinedInput = styled(OutlinedInput)(
    (theme: { className: string }) => {
      
      const color=  this.inputBackgroundAndBorderColor(theme.className)

      return {
      backgroundColor: "#ffff",
      zIndex: 2,
      "& input": {
        width: "550px"
      },
      "& fieldset": {
        border:color.borderColor,
        background:color.backgroundColor
      }
    }}
  );

  StyledLink = styled(Link)({
    textDecoration:"none",
    color: "#2596BE", 
    margin:"0 5px"
  });
  
  StyledForgetPasswordLink = styled(Link)({
    color: "#5E5E5E", 
    margin:"0 10px",
    lineHeight:"2.8",
    fontSize: "1.2rem",
    fontFamily: "Source Sans Pro"
  });
  

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <BasicLayout>
          <Box style={webStyle.signUpContainerBoxSection2}>
                
            <Typography variant="h6">
              Welcome back!
            </Typography>
            <Typography variant="subtitle2" style={webStyle.signUpSubTitle}>
              Sign in to your account
            </Typography>
            <StyledErrorOnLimit className={this.errorVisibilty(this.state.limitReached)}>
              <IconButton disabled style={webStyle.signUpErrorIcon}>
                <InfoOutlinedIcon />
              </IconButton>
              <div>
              You Reached maximum of login try.Please try after 60:00 minute
              </div>
            </StyledErrorOnLimit>
            <Box style={webStyle.signUpFormSection}>
              <StyledInputContainer>
                <Typography variant="subtitle1">Email</Typography>
                <FormControl variant="outlined">
                  <this.StyledOutlinedInput
                    className={this.inputValidationClassName(this.state.isEmailValid)}
                    type="text"
                    name="email"
                    placeholder="example@mail.com"
                    data-testid = "emailInput"
                    endAdornment={
                      <InputAdornment position="end">
                        <StyledIconButton
                          disabled
                          className={
                            this.state.isEmailValid === null
                              ? "hidden"
                              : ""
                          }
                        >
                          {this.state.isEmailValid && (
                            <CheckCircleOutlineOutlined
                              style={webStyle.signUpSuccessIcon}
                            />
                          )}
                          {!this.state.isEmailValid && (
                            <InfoOutlinedIcon
                              style={webStyle.signUpErrorIcon}
                            />
                          )}
                        </StyledIconButton>
                      </InputAdornment>
                    }
                    onChange={this.onEmailInputChange}
                    onBlur={()=>this.validateLoginEmail()}
                    value={this.state.email}
                  />
                </FormControl>
                <div style={webStyle.signUpError}>
                  <StyledErrorLabel
                    variant="caption"
                    className={this.errorVisibilty((this.state.isEmailValid === false || this.state.isLoginError))}
                  >
                  {this.state.isEmailValid === false &&  "Please enter a valid email address."}
                  {this.state.isLoginError &&  this.state.loginErrorMsg}
                  {(this.state.isEmailValid || this.state.isEmailValid === null) && !this.state.isLoginError && "Hi " }
                  </StyledErrorLabel>
                </div>
              </StyledInputContainer>
              <StyledInputContainer>
                <Typography variant="subtitle1">
                  Password
                </Typography>
                <FormControl variant="outlined">
                  <this.StyledOutlinedInput
                    name="password"
                    className={
                      this.state.invalidPassword? "fail": ""
                    }
                    type={this.changeInputType(this.state.showPassword)}
                    placeholder="Enter your password"
                    data-testid = "passwordInput"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={this.onShowPassword} data-testid = "showPasswordBtn">
                          {this.state.showPassword && !this.state.invalidPassword && (
                            <VisibilityOffIcon/>
                          )}
                          {!this.state.showPassword && !this.state.invalidPassword && (
                            <VisibilityIcon/>
                          )}
                          {this.state.invalidPassword && (
                            <InfoOutlinedIcon
                              style={webStyle.signUpErrorIcon}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={this.onPasswordInputChange}
                    value={this.state.loginPassword}
                    onFocus = {()=>{this.setState({invalidPassword:false})}}
                  />
                </FormControl>
                <div style={webStyle.signUpError}>
                  <StyledErrorLabel
                    variant="caption"
                    className= {this.state.invalidPassword ? "visible" :""}
                  >
                    Please Enter a valid Password.
                  </StyledErrorLabel>
                </div>
              </StyledInputContainer>
              <div style={webStyle.signUpButtonContainer}>
                <StyledButton
                  variant="contained"
                  data-testid = "loginBtn"
                  onClick={this.onClickLoginBtn}>
                  Sign In
                </StyledButton>
                <div >
                  <this.StyledForgetPasswordLink to={configJSON.forgetPasswordPath}>Forgot password?</this.StyledForgetPasswordLink>
                </div>
              </div>
              <StyledInputContainer>
                New here ?<span><this.StyledLink to={configJSON.signUpPath} >Sign Up</this.StyledLink></span>
              </StyledInputContainer>
            </Box>
          </Box>
        </BasicLayout>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End