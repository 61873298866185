import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  phone: string;
  signUpPassword: string;
  signUpConfirmPassword: string;
  signUpSuccessful: boolean;
  isEmailValid: boolean | null;
  isPasswordValid: boolean | null;
  isConfirmPasswordMatch: boolean | null;
  isPhoneValid: boolean | null;
  showPassword: boolean;
  showConfirmPassword: boolean;
  signUpError: boolean,
  signUpErrorMsg: string, 
  consultantSignUp: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  subScribedMsg: string[];
  signUpRequestId:string ="";  
  profileRequestId:string= ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMsg = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.AuthTokenDataMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      email: "",
      phone: "",
      signUpPassword: "",
      signUpConfirmPassword: "",
      signUpSuccessful: false,
      isEmailValid: null,
      isPasswordValid: null,
      isConfirmPasswordMatch: null,
      isPhoneValid: null,
      showPassword: false,
      showConfirmPassword: false,
      signUpError: false,
      signUpErrorMsg: "",
      consultantSignUp: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMsg);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      message.getData(getName(MessageEnum.AuthTokenDataMessage));

      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch(apiRequestId){
        case this.signUpRequestId:
          if(responseJson.meta){
            this.setState({signUpSuccessful:true, signUpError: false})
          }
          else if(responseJson.errors){
          this.setState({signUpError:true})
          }
          break
        case this.profileRequestId:
          if (responseJson.data){
            const userDetails = JSON.stringify({
              unique_auth_id: responseJson.data.attributes.unique_auth_id,
              first_name: responseJson.data.attributes.first_name,
              last_name: responseJson.data.attributes.last_name,
              role: responseJson.data.attributes.role.name,
            })
            /*istanbul ignore next*/
            window.localStorage.setItem("userDetails",userDetails)
            /*istanbul ignore next*/
            this.redirectUser(responseJson.data.attributes.role.name)
  
          } 
          else if(responseJson.errors){
            window.localStorage.setItem("web_token","")
            window.localStorage.setItem("web_refresh_token","")
            window.localStorage.setItem("userDetails","")
            window.localStorage.setItem("auth","false")
          }
          break
        default: 
          break
      }

    }

    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  validateEmail = () => {
   if(/\w+/.test(this.state.email)){
      const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$/i;
      if (emailRegex.test(this.state.email)) {
        this.setState({ isEmailValid: true });
      } else {
        this.setState({ isEmailValid: false });
      }
    }else{
      this.setState({ isEmailValid: false });
    }
  };


  validatePhone = () => {
    const phoneRegex = /\(\d{3}\) \d{3} \d{4}/;

    if (phoneRegex.test(this.state.phone)) {
      this.setState({ isPhoneValid: true });
    } else {
      this.setState({ isPhoneValid: false });
    }
  };

  validatePassword = () => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;
    if (regex.test(this.state.signUpPassword)) {
      this.setState({ isPasswordValid: true });
    } else {
      this.setState({ isPasswordValid: false });
    }
  };

  matchPassword = (callBack?: () => void) => {
    console.log(callBack);
    if (
      this.state.signUpConfirmPassword === this.state.signUpPassword &&
      this.state.signUpConfirmPassword.length > 0
    ) {
      this.setState({ isConfirmPasswordMatch: true }, () => {
        if (typeof callBack === "function") {
          callBack();
        }
      });
    } else {
      this.setState({ isConfirmPasswordMatch: false });
    }
  };

  onShowPassword = () => {
    if (this.state.showPassword) {
      this.setState({ showPassword: false });
    } else {
      this.setState({ showPassword: true });
    }
  };

  onShowConfirmPassword = () => {
    if (this.state.showConfirmPassword) {
      this.setState({ showConfirmPassword: false });
    } else {
      this.setState({ showConfirmPassword: true });
    }
  };

  onEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value.trim() }, () =>
      this.validateEmail()
    );
  };

  onPhoneInputChange = (event: React.ChangeEvent<HTMLInputElement> ) => {
    let inputVal = event.target.value;
    const data = (event.nativeEvent as InputEvent).data;
    if (data !== null) {
      // Remove all non-digit characters from the input
      inputVal = inputVal.replace(/\D/g, "");

      // Format the input as (xxx) xxx xxxx
      if (inputVal.length > 0) {
        inputVal = `(${inputVal.slice(0, 3)}) ${inputVal.slice(
          3,
          6
        )} ${inputVal.slice(6, 10)}`;
      }
    }

    this.setState({ phone: inputVal }, () => this.validatePhone());
  };

  onPasswordInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ signUpPassword: event.target.value.trim() }, () =>
      this.validatePassword()
    );
  };

  onConfirmPasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ signUpConfirmPassword: event.target.value.trim() }, () =>
      this.matchPassword()
    );
  };

  inputValidationClassName = (success:boolean|null):string=>{
      switch(success){
        case true:
          return "success"
        case false:
          return "fail"
        default:
          return ""
      }
  }

  inputBoderAndBackgroundColor= (msg:string)=>{

    switch(msg){
      case "success":{
      return { borderColor :"1px solid rgba(8, 183, 26, 0.2)",backgroundColor : "rgba(8, 183, 26, 0.04)"}
      }
      case "fail":{
        return {borderColor :"1px solid #FF0000",backgroundColor :"rgba(255, 0, 0, 0.02)"}
      }
      default:{
       return {borderColor : "",backgroundColor : ""}
      }
    }
  }

  inputTypeText = (showPass:boolean):string =>{
    if(showPass){
      return "text"
    }else{
      return "password"
    }
  }

  errorVisibiltyClass=(visible:boolean | null)=>{
    if(visible){
      return "visible"
    }else{
      return ""
    }

  }

  onSignUpSubmit = () => {
    const callBackFuction = () => {
      if (
        this.state.isConfirmPasswordMatch &&
        this.state.isEmailValid &&
        this.state.isPasswordValid &&
        this.state.isPhoneValid
      ) {
        this.signUpRequestCall()
      }
    };
    this.validateEmail();
    this.validatePassword();
    this.validatePhone();
    this.matchPassword(callBackFuction);
  };

  redirectUser = (role:string, mockWindowObj?: { location: { replace: Function } })=>{
    const redirectWindow = mockWindowObj || window;
    switch(role){
      case "admin":
        redirectWindow.location.replace(configJSON.adminDashboardPath)
        break
      case "employee":
       redirectWindow.location.replace(configJSON.employeeDashboardPath)
        break
      case "consultant":
       redirectWindow.location.replace(configJSON.consultantDashboardPath)
        break
      default: 
       redirectWindow.location.replace(configJSON.loginPath)
    }
    
  }

  signUpRequestCall =() =>{
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };

    let body = {
      "data": {
        "attributes": {
          "email": this.state.email,
          "password": this.state.signUpPassword,
          "full_phone_number": this.state.phone,
          "activated": false
        },
        "type": "email_account"
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.signUpRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }

  getUserProfileDetials = async ()=>{
    const token = window.localStorage.getItem("web_token")

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token" : token
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.profileRequestId = requestMessage.messageId
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/logged_user`,
    );  
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet,
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  getQueryParams = () =>{
    const queryParams = new URLSearchParams(window.location.search);
    const params:Record<string,string|undefined> = {}
    for (const [key, value] of queryParams) {
      params[key] = value;
    }

    return params
  }

  async componentDidMount() {
    const auth = window.localStorage.getItem("auth")
    const  params = this.getQueryParams()
   
    if(params['email'] !== undefined  ){
      this.setState({email:params['email'], consultantSignUp: true},this.validateEmail)
    }

    if(auth === "true"){
      this.getUserProfileDetials()
    }
  }
  // Customizable Area End
}
