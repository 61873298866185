import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { configJSON } from "../DashboardController.web";
import ImageWithName from "./ImageWithName";
import { IRecommendedConsultant } from "../interfaces";
import {
  StyledExperties,
  StyledTableCellContainerDiv,
  StyledTableRow,
  TableContainer,
  ScheduleAppointmentWrapper,
  CustomTableCell,
  CunstomTableHead,
  CustomTableRow,
  TableContainerLabel,
  RecommendedTableContainerBox
} from "../../../forgot-password/src/Components/CommonStyledComponent";

interface Props {
  consultants: IRecommendedConsultant[];
  openAppointmentForm: (id: string, calendly_uri: string) => void;
}

const RecommendedConsultant = (props: Props) => {
  return (
    <TableContainer elevation={3}>
      <TableContainerLabel variant="h6">
        Recommended consultant
      </TableContainerLabel>
      <RecommendedTableContainerBox>
        <Table>
          <CunstomTableHead>
            <CustomTableRow>
              {configJSON.employeeConsultantTableHeaderArr.map(
                (label: string) => (
                  <CustomTableCell key={label}>{label}</CustomTableCell>
                )
              )}
            </CustomTableRow>
          </CunstomTableHead>
          <TableBody>
            {props.consultants.map(consultant => (
              <StyledTableRow key={consultant.id}>
                <TableCell>
                  <StyledTableCellContainerDiv>
                    <ImageWithName
                      name={`${consultant.attributes.full_name}`}
                      imgUrl={
                        consultant.attributes.profile.data.attributes.photo
                      }
                    />
                  </StyledTableCellContainerDiv>
                </TableCell>
                <TableCell>
                  <StyledTableCellContainerDiv>
                    {consultant.attributes.profile.data.attributes
                      .area_of_expertise &&
                      consultant.attributes.profile.data.attributes.area_of_expertise.map(
                        (experties: string, index: number) => (
                          <StyledExperties key={`${index}-${experties}`}>
                            {experties}
                          </StyledExperties>
                        )
                      )}
                  </StyledTableCellContainerDiv>
                </TableCell>
                <TableCell>
                  <StyledTableCellContainerDiv>
                    {consultant.attributes.profile.data &&
                      consultant.attributes.profile.data.attributes.company}
                  </StyledTableCellContainerDiv>
                </TableCell>
                <TableCell>
                  <StyledTableCellContainerDiv>
                    {consultant.attributes.no_of_employees}
                  </StyledTableCellContainerDiv>
                </TableCell>
                <TableCell>
                  <StyledTableCellContainerDiv>
                    {consultant.attributes.no_of_employees}
                  </StyledTableCellContainerDiv>
                </TableCell>
                <TableCell>
                  <StyledTableCellContainerDiv
                    onClick={() =>
                      props.openAppointmentForm(
                        consultant.id,
                        consultant.attributes.calendly_uri
                      )
                    }
                  >
                    <ScheduleAppointmentWrapper>
                      Schedule Appointment
                    </ScheduleAppointmentWrapper>
                  </StyledTableCellContainerDiv>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </RecommendedTableContainerBox>
    </TableContainer>
  );
};

export default RecommendedConsultant;
