import { RootProfile } from "../types/profile";
import { MyProfile } from "../components/Settings/components/MyProfile/types/profile";

export const mapRootProfile = ({
  attributes: { profile, email: mail, full_name, photo: imgUrl, role }
}: RootProfile): MyProfile => {
  const {
    id,
    country,
    city,
    about_me,
    expertise: experts,
    area_of_expertise
  } = profile.data.attributes;

  const name = full_name || "N/A";
  const email = mail || "N/A";
  const about = about_me || "N/A";
  const expertise = experts || "N/A";
  const skills = area_of_expertise ? area_of_expertise : ["N/A"];
  const position = role.name ? role.name : "N/A";
  const location =
    country || city ? `${country || "N/A"}, ${city || "N/A"}` : "N/A";
  const photo = imgUrl && imgUrl.url ? imgUrl.url : null;

  return {
    id,
    name,
    email,
    about,
    expertise,
    skills,
    position,
    location,
    photo
  };
};
