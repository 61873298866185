import React from "react";

import {
  Container,
  Box,
  // Customizable Area Start
  AppBar,
  Avatar,
  IconButton
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  SearchInput,
  MenuButton,
  SideBarMenuButtonText,
  TermsAndConditionsButtonText,
  AddAppointmentButton,
  ExpandMoreIconWrapper,
  PageSectionHeaderTitle,
  PagesContainer
} from "./styledElements";
import { searchIcon, logoIcon } from "./assets";
import HomeIcon from "./components/icons/DashboardHomeIcon";
import DashboardConsultantsIcon from "./components/icons/DashboardConsultantsIcon";
import SettingIcon from "./components/icons/SettingIcon";
import LogoutIcon from "./components/icons/LogoutIcon";
import TermsAndConditionIcon from "./components/icons/TermsAndConditionIcon";
import { ExpandMore } from "@material-ui/icons";
import AddNewAppointmentIcon from "./components/icons/AddNewAppointmentIcon";
import { AddAppointmentForm } from "./components/AddNewAppointMent";
import { Dashboard } from "./components/Dashboard";
import { Appointment } from "./components/Appointments";
import { MyConsultant } from "./components/MyConsultantSection";
import { Settings } from "./components/Settings";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import { LogoutPopUp } from "./components/LogutPopUp";
import TermsAndCondition from "../../TermsAndConditions3/src/TermsAndConditions3.web";
import { webStyle } from "../../forgot-password/src/Components/webStyles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});
// Customizable Area End

import EmployeeDashBoardController, {
  Props,
  configJSON
} from "./DashboardController.web";
// import { Calendly } from "./components/Calendly";

export default class EmployeeDashBoard extends EmployeeDashBoardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container disableGutters maxWidth={false}>
          <AppBar>
            <Box sx={webStyle.appBarContainer}>
              <img src={logoIcon} width={86} height={43} />
              <Box sx={webStyle.searchContainer}>
                {(this.state.selectedPage === "dashboard" ||
                  this.state.selectedPage === "appointments" ||
                  this.state.selectedPage === "consultants") && (
                  <>
                    <Box sx={webStyle.searchIcon}>
                      <img src={searchIcon} />
                    </Box>
                    <SearchInput
                      placeholder={configJSON.searchInputPlaceholder}
                      value={this.state.search}
                      onChange={this.onSearchChange}
                    />
                  </>
                )}
              </Box>
              <Box sx={webStyle.avatarSectionContainer}>
                <Box sx={webStyle.avatarWithActionContainer}>
                  <IconButton onClick={() => this.setSelectedPage("settings")}>
                    <Avatar
                      style={webStyle.avatar}
                      src={
                        this.state.userProfile && this.state.userProfile.photo
                          ? this.state.userProfile.photo
                          : ""
                      }
                    />
                  </IconButton>
                  <IconButton style={webStyle.displayNone}>
                    <ExpandMoreIconWrapper>
                      <ExpandMore />
                    </ExpandMoreIconWrapper>
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </AppBar>

          <Box sx={webStyle.mainWrapper}>
            <Box sx={webStyle.leftMenu}>
              <Box sx={webStyle.menuWrapper}>
                <Box sx={webStyle.upperMenuWrapper}>
                  <MenuButton
                    startIcon={<HomeIcon />}
                    style={
                      this.state.selectedPage === "dashboard"
                        ? webStyle.activeButton
                        : webStyle.noActiveButton
                    }
                    onClick={() => this.setSelectedPage("dashboard")}
                    data-test-id="dashboardBtn"
                  >
                    <SideBarMenuButtonText data-text={configJSON.dashboardText}>
                      {configJSON.dashboardText}
                    </SideBarMenuButtonText>
                  </MenuButton>

                  <MenuButton
                    startIcon={<CalendarTodayOutlinedIcon />}
                    style={
                      this.state.selectedPage === "appointments"
                        ? webStyle.activeButton
                        : webStyle.noActiveButton
                    }
                    onClick={() => this.setSelectedPage("appointments")}
                    data-test-id="appointmentBtn"
                  >
                    <SideBarMenuButtonText
                      data-text={configJSON.appointmentText}
                    >
                      {configJSON.appointmentText}
                    </SideBarMenuButtonText>
                  </MenuButton>

                  <MenuButton
                    startIcon={<DashboardConsultantsIcon />}
                    style={
                      this.state.selectedPage === "consultants"
                        ? webStyle.activeButton
                        : webStyle.noActiveButton
                    }
                    onClick={() => this.setSelectedPage("consultants")}
                  >
                    <SideBarMenuButtonText
                      data-text={configJSON.consultantText}
                    >
                      {configJSON.consultantText}
                    </SideBarMenuButtonText>
                  </MenuButton>
                </Box>
                <Box sx={webStyle.footMenuWrapper}>
                  <MenuButton
                    startIcon={<SettingIcon />}
                    style={
                      this.state.selectedPage === "settings"
                        ? webStyle.activeButton
                        : webStyle.noActiveButton
                    }
                    onClick={() => this.setSelectedPage("settings")}
                  >
                    <SideBarMenuButtonText data-text={configJSON.settingText}>
                      {configJSON.settingText}
                    </SideBarMenuButtonText>
                  </MenuButton>
                  <MenuButton
                    startIcon={<LogoutIcon />}
                    onClick={this.handleOpenLogoutForm}
                  >
                    <SideBarMenuButtonText data-text={configJSON.logoutText}>
                      {configJSON.logoutText}
                    </SideBarMenuButtonText>
                  </MenuButton>
                </Box>
              </Box>

              <LogoutPopUp
                open={this.state.openLogoutForm}
                handleClose={this.handleCloseLogoutForm}
                logout={this.logoutUser}
              />

              <Box sx={webStyle.termsAndConditionsWrapper}>
                <MenuButton
                  startIcon={<TermsAndConditionIcon />}
                  style={
                    this.state.selectedPage === "TermsAndCondition"
                      ? webStyle.activeButton
                      : {}
                  }
                  onClick={() => this.setSelectedPage("TermsAndCondition")}
                >
                  <TermsAndConditionsButtonText
                    data-text={configJSON.termAndConditionText}
                  >
                    {configJSON.termAndConditionText}
                  </TermsAndConditionsButtonText>
                </MenuButton>
              </Box>
            </Box>

            <Box sx={webStyle.mainSection}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap"
                }}
              >
                <PageSectionHeaderTitle>
                  {this.state.selectedPage === "dashboard" &&
                    configJSON.dashboardPageTitle}
                  {this.state.selectedPage === "appointments" &&
                    configJSON.appointmentPageTitle}
                  {this.state.selectedPage === "consultants" &&
                    configJSON.consultantPageTitle}
                  {this.state.selectedPage === "settings" &&
                    configJSON.settingsPageTitle}
                </PageSectionHeaderTitle>

                {this.state.selectedPage !== "settings" &&
                  this.state.selectedPage !== "TermsAndCondition" && (
                    <AddAppointmentButton
                      startIcon={<AddNewAppointmentIcon />}
                      onClick={this.handleOpenAddNewAppointment}
                    >
                      {configJSON.addNewAppointmentLabel}
                    </AddAppointmentButton>
                  )}

                <AddAppointmentForm
                  open={this.state.openAddNewAppointment}
                  onClose={this.handleCloseAddNewAppointment}
                  listOfConsultant={this.state.consultantList}
                  selectedConsultant={this.state.selectedConsultantId}
                  handleSelectConsultant={this.handleSelectConsultant}
                  calendlyUri={this.state.calendlyUri}
                  profileDetail={this.state.profileDetail}
                />
              </Box>

              <PagesContainer>
                {this.state.selectedPage === "dashboard" && (
                  <Dashboard
                    recommended={this.state.recommendedConsultant}
                    openAppointmentForm={this.handleOpenAddNewAppointmentTable}
                  />
                )}

                {this.state.selectedPage === "appointments" && (
                  <Appointment
                    appointments={this.state.appointments}
                    handleChange={this.handleAppointmentPageChange}
                    currentPage={this.state.currentPage}
                    totalPage={this.state.totalPages}
                    openForm={this.state.cancelFormOpen}
                    handleFormClose={this.handleCloseCancelForm}
                    handleOpenForm={this.handleOpenCancelForm}
                    cancelAppointment={this.cancelAppointment}
                  />
                )}
                {this.state.selectedPage === "consultants" && (
                  <MyConsultant
                    consultants={this.state.myConsultant}
                    handleChange={this.handleConsultantPageChange}
                    currentPage={this.state.currentPage}
                    totalPage={this.state.totalPages}
                  />
                )}
                {this.state.selectedPage === "settings" &&
                  this.state.userProfile && (
                    <Settings
                      data={this.state.userProfile}
                      uploadPhoto={this.uploadPhoto}
                      updatePassword={this.updateUserPassword}
                      updateProfile={this.updateUserProfile}
                    />
                  )}
                {this.state.selectedPage === "TermsAndCondition" && (
                  <TermsAndCondition navigation={""} id="terms" />
                )}
              </PagesContainer>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
